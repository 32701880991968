
/* Overlay Start */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    inset-inline-start: 0;
    inset-block-start: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show-overlay {
        visibility: visible;
        opacity: 1;
    }
}

.side-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    inset-inline-start: 0;
    inset-block-start: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
    }
}
/* Overlay End */