/* ================================= Range Slider Css Start =========================== */
.custom--range {
	#slider-range {
		height: 6px;
		border: 0;
		background: var(--neutral-50);
        margin: 8px;
		.ui-slider-handle {
			width: 7px !important;
			height: 15px !important;
			background-color: #fff !important;
			border-radius: 50rem;
			top: 50%;
			transform: translateY(-50%);
			outline: 0 !important;
			transition: 0.2s linear;
			border: 1px solid var(--main-600);
			&:hover, &.ui-state-active {
				transform: translateY(-50%) scale(1.3);
			}
			&.ui-state-active {
                box-shadow: 0px 0px 12px 4px #95959541 !important;  
			}
		}
		.ui-widget-header {
			background-color: var(--main-600);
            transition: .2s linear;
		}
		span {
			&:focus {
				background-color: var(--main-600);
			}
		}
	}

	&.style-two {
		#slider-range { 
			.ui-slider-handle {
				width: 15px !important;
				height: 15px !important;
			}
		}
	}
}
/* ================================= Range Slider Css End =========================== */