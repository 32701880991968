
/* Box shadow start */
.box-shadow {
    &-sm {
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);  
    }
    &-md {
      box-shadow: 0 6px 30px 0px  rgba(0, 0, 0, 0.04);  
    }
    &-lg {
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);  
    }
    &-xl {
      box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);  
    }
    &-2xl {
      box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);  
    }
    &-inner {
      box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);  
    }
}

.shadow-main-two {
  box-shadow: 0 6px 30px 0 hsl(var(--main-two)/.5);
}

.shadow-main-three {
  box-shadow: 0 6px 30px 0 hsl(var(--main-three)/.5);
}
/* Box shadow End */
