/* ================================= Faq Section Start ============================ */
.faq-thumb {
    @media (min-width: 1199px) {
        position: absolute;
        inset-inline-end: 54%;
        top: 50%;
        transform: translateY(-50%);
        padding-inline-start: 24px;
    }
}
/* ================================= Faq Section End ============================ */