/* text Circle Rotation */
.circle {
    position: relative;
    // margin-bottom: 24px;
    width: 158px;
    height: 158px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}



.circle.style-two .circle__text span {
    transform-origin: 0 76px;
}

@media screen and (max-width: 575px) {
    .circle.style-two .circle__text span {
        font-size: 14px;
    }
}

@media screen and (max-width: 575px) {
    .circle.style-two .circle__text span {
        transform-origin: 0 58px;
    }
}


.circle.static-circle {
    border-color: hsl(var(--static-white));
    border-color: hsl(var(--static-white));
    background-color: hsl(var(--static-white)/0.5);
}

.circle.static-circle .circle__badge {
    border-color: hsl(var(--static-white));
}


.circle.static-circle .circle__text span {
    color: hsl(var(--static-black));
}

.circle__badge {
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
}

.circle__text {
    position: absolute;
    width: 100%;
    height: 100%;
    color: hsl(var(--heading-color));
    font-size: 1rem;
    animation: textRotation 8s linear infinite;
}

.circle__text span {
    position: absolute;
    left: 50%;
    font-size: 1rem;
    font-family: "inter";
    transform-origin: 0 78px;
    color: hsl(var(--heading-color));
    font-weight: 500;
}

@keyframes textRotation {
    to {
        transform: rotate(360deg);
    }
}
/* text Circle Rotation */