/* Font Size Class Start */
.text {
    &-inherit {
        font-size: inherit;
    }
}

@each $property, $value in $font-sizes {
    .text-#{$property} {
      font-size: $value !important;
    }
}  

@each $property, $value in $spaces {
    .text-#{$property} {
      font-size: $value !important;
    }
}
.font-size-20-px{
  font-size: clamp(1rem, 0.25rem + 1.5625vw, 1.25rem);;
}
/* Font Size Class End */