
/* ================================= Social Icon Css Start =========================== */
.social-hover {
    &:hover {
        .social-list {
            &__item {
                visibility: visible;
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
    .social-list {
        &__item {
            visibility: hidden;
            opacity: 0;
            transform: translateY(24px);
            transition: .2s linear;
            &:nth-child(1) {
                transition-delay: 0s;
            }
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            &:nth-child(4) {
                transition-delay: 0.3s;
            }
        }
    }
}
/* ================================= Social Icon Css End ===========================  */