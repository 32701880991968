/* ================================= Padding Css Start =========================== */
@each $property, $value in $spaces {
  .p-#{$property} {
    padding: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .px-#{$property} {
    padding-inline: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .py-#{$property} {
    padding-block: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .ps-#{$property} {
    padding-inline-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pe-#{$property} {
    padding-inline-end: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pt-#{$property} {
    padding-block-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .pb-#{$property} {
    padding-block-end: var(--size-#{$property}) !important;
  }
}

.px-60 {
  padding-inline-start:clampCal(24, 60) !important;
  padding-inline-end:clampCal(24, 60) !important; 
}

.py-60 {
  padding-top: clampCal(24, 60) !important;
  padding-bottom: clampCal(24, 60) !important;
}
.px-32 {
  padding-inline-start:clampCal(16, 32) !important;
  padding-inline-end:clampCal(16, 32) !important;
}
.py-40 {
  padding-inline-start:clampCal(24, 40) !important;
  padding-inline-end:clampCal(24, 40) !important;
}
.px-40 {
  padding-inline-start:clampCal(24, 40) !important;
  padding-inline-end:clampCal(24, 40) !important;
}
.py-240 {
  padding-top: clampCal(120, 240) !important;
  padding-bottom: clampCal(120, 240) !important;
}
.p-32 {
  padding: clampCal(16, 32) !important;
}
.p-40 {
  padding: clampCal(20, 40) !important;
}

.pt-0 {
  padding-block-start: 0!important;
}

.pb-0 {
  padding-block-end: 0!important;
}

.ps-0 {
  padding-inline-start: 0!important;
}

.pe-0 {
  padding-inline-end: 0!important;
}


.py-120 {
  padding-block-start: 60px !important;
  padding-block-end: 60px !important;
  @media (min-width: 576px) {
    padding-block-start: 80px !important;
    padding-block-end: 80px !important;
  }
  @media (min-width: 992px) {
    padding-block-start: 120px !important;
    padding-block-end: 120px !important;
  }
}
.pt-120 {
    padding-block-start: 60px !important;
    @media (min-width: 576px) {
    padding-block-start: 80px !important;
  }
    @media (min-width: 992px) {
    padding-block-start: 120px !important;
  }
}
.pb-120 {
    padding-block-end: 60px !important;
    @media (min-width: 576px) {
    padding-block-end: 80px !important;
  }
    @media (min-width: 992px) {
    padding-block-end: 120px !important;
  }
}
.py-60 {
    padding-block-start: 30px !important;
    padding-block-end: 30px !important;
    @media (min-width: 576px) {
    padding-block-start: 40px !important;
    padding-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-start: 60px !important;
    padding-block-end: 60px !important;
  }
}
.pt-60 {
    padding-block-start: 30px !important;
    @media (min-width: 576px) {
    padding-block-start: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-start: 60px !important;
  }
}
  .pb-60 {
    padding-block-end: 30px !important;
    @media (min-width: 576px) {
    padding-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    padding-block-end: 60px !important;
  }
}
/* ================================= Padding Css End =========================== */
