
/* ================================= Classes Css Start =========================== */
.container {
    &--lg {
        max-width: 1600px !important;
    }
    &--xl {
        max-width: 1760px !important;
    }
}

.grid-cols {
    &-2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        gap: 24px;
    }
    &-4 {
        gap: 24px;
        display: grid;
        min-width: min-content;
        grid-template-columns: 1fr 1fr 1fr 220px;
    }
}

/* Section Background */
.section-bg {
    background-color: hsl(var(--section-bg)) !important;
}

/* Column Extra Small Screen */
.col-xs-6 {
    @media screen and (min-width: 425px) and (max-width: 575px) {
        width: 50%;
    }
}

.item-active-effect {
    &:active {
        transform: scale(.9);
    }
}

.list-dotted {
    list-style: disc;
    padding-inline-start: 2rem;
}

.outline-0 {
    outline: none !important;
}

/* Transition */
.transition {
    &-1 {
        transition: .1s linear;
    }
    &-2 {
        transition: .2s linear;
    }
    &-3 {
        transition: .3s linear;
    }
}

.min-width-max-content {
    min-width: max-content;
}

.inset {
    &-inline-start-0 {
        inset-inline-start: 0;
    }
    &-inline-end-0 {
        inset-inline-end: 0;
    }
    &-inline-end--64 {
        inset-inline-end: -64px !important;
        @media (max-width: 991px) {
            inset-inline-end: 24px !important;
        }
    }
    &-block-start-0 {
        inset-block-start: 0;
    }
    &-block-end-0 {
        inset-block-end: 0;
    }
}

.text-xl-res {
    font-size: clampCal(15, 20);
}

.end-n4 {
    inset-inline-end: -6px;
}
.top-n6 {
    inset-block-start: -6px;
}

.start {
    &-auto {
        inset-inline-start: auto !important;
    }
}

.end {
    &-auto {
        inset-inline-end: auto !important;
    }
}

.pointer-event-none {
    pointer-events: none;
}

/* Text Color */
.hover-text {
    &-decoration-underline {
        &:hover {
            text-decoration: underline;
        }
    }
    &-decoration-none {
        &:hover {
            text-decoration: none;
        }
    }
}

.font {
    &-heading {
        font-family: var(--heading-font);
    }
    &-body {
        font-family: var(--body-font);
    }
}

/* Bg Image Css */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}

.scale-hover-item {
    &:hover {
        .scale-hover-item__img {
            transform: scale(1.2);
        }
    }
}

.aspect-ratio-1 {
    aspect-ratio: 1;
}

.positioned-rotation {
    position: absolute;
    inset-inline-end: -33%;
    transform: rotate(45deg);
    width: 100%;
    top: 8%;
}

.border-trans {
    border-color: transparent;
}

.bg {
    &-table {
        background-color: #9e7152;
    }
    &-watch {
        background-color: #faddc4;
    }
    &-pen {
        background-color: #e4eaed
    }
}
.vertical-middle {
    vertical-align: middle;
}

.min-w-max {
    min-width: max-content;
}
/* ================================= Classes Css End =========================== */