/* ======================== Course List View Css Start ============================= */
.sidebar {
    @include xl-screen {
        padding: 24px !important;
    }
    @include lg-screen {
        padding: 16px !important;
    }
    @include md-screen {
        position: fixed;
        inset-inline-start: 0;
        top: 0;
        z-index: 99;
        width: 100%;
        max-width: 348px;
        border-radius: 0 !important;
        border: 0 !important;
        border-inline-end: 1px solid var(--neutral-30) !important;
        transform: translateX(-100%);
        transition: .2s linear;
        overflow-y: auto;
        height: 100vh;
        &.active {
            transform: translateX(0%);
        }
    }
}


/* ======================== Course List View Css End ============================= */