/*====================== CSS Index Here =======================*/
/*
01. Abstracts
    1.1. Classes
    1.2. Extend
    1.3. Functions
    1.4. Mixins
    1.5. Variable
    1.6. Wordpress Default

02. Base
    2.1. Margin
    2.2. Padding
    2.3. Typography

03. Components
    3.1. Accordion
    3.2. Button
    3.3. Form
    3.4. Pagination
    3.6. Tab

04. Layout
    4.1. Blog Sidebar
    4.2. Breadcrumb
    4.3. Comment
    4.4. Footer
    4.5. Header Top
    4.6. Header
    4.7. Preloader
    4.8. Scroll Top
    4.9. Section Heading
    4.10. Slick
    4.11. Social Icon
*/
/*======================== CSS Index End ======================*/