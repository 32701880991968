/* ================================= Margin Css Start =========================== */
@each $property, $value in $spaces {
  .m-#{$property} {
    margin: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mx-#{$property} {
    margin-inline: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .my-#{$property} {
    margin-block: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .ms-#{$property} {
    margin-inline-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .me-#{$property} {
    margin-inline-end: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mt-#{$property} {
    margin-block-start: var(--size-#{$property}) !important;
  }
}

@each $property, $value in $spaces {
  .mb-#{$property} {
    margin-block-end: var(--size-#{$property}) !important;
  }
}

.ms--40 {
  margin-inline-start: -40px;
}

.ms--6 {
  margin-inline-start: -6px;
}

@media (min-width: 992px) {
  .ms-lg-4 {
      margin-inline-start: 1.5rem !important;
  }
}

.mt--5 {
  margin-block-end: -5px;
}

.me--5 {
  margin-inline-end: -5px;
}

.mt-0 {
  margin-block-start: 0!important;
}

.mb-0 {
  margin-block-end: 0!important;
}

.ms-0 {
  margin-inline-start: 0!important;
}
.ms--150px {
  @media (min-width: 991px) {
    margin-inline-start: -150px !important;
  }
  margin-inline-start: 24px;
}

.mt--200px {
  @media (min-width: 991px) {
    margin-top: -200px !important;
  }
}

.me-0 {
  margin-inline-end: 0!important;
}

.me-148-px {
margin-inline-end: clamp(1.25rem, -26.75rem + 32vw, 9.25rem);;
}
.ms-148-px {
margin-inline-start: clamp(1.25rem, -26.75rem + 32vw, 9.25rem);;
}


.my-120 {
  margin-block-start: 60px !important;
  margin-block-end: 60px !important;
  @media (min-width: 576px) {
    margin-block-start: 80px !important;
    margin-block-end: 80px !important;
  }
  @media (min-width: 992px) {
    margin-block-start: 120px !important;
    margin-block-end: 120px !important;
  }
}
.mt-120 {
    margin-block-start: 60px !important;
    @media (min-width: 576px) {
    margin-block-start: 80px !important;
  }
    @media (min-width: 992px) {
    margin-block-start: 120px !important;
  }
}
.mb-120 {
    margin-block-end: 60px !important;
    @media (min-width: 576px) {
    margin-block-end: 80px !important;
  }
    @media (min-width: 992px) {
    margin-block-end: 120px !important;
  }
}
.my-60 {
    margin-block-start: 30px !important;
    margin-block-end: 30px !important;
    @media (min-width: 576px) {
    margin-block-start: 40px !important;
    margin-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    margin-block-start: 60px !important;
    margin-block-end: 60px !important;
  }
}
.mt-60 {
    margin-block-start: 30px !important;
    @media (min-width: 576px) {
    margin-block-start: 40px !important;
  }
    @media (min-width: 992px) {
    margin-block-start: 60px !important;
  }
}
  .mb-60 {
    margin-block-end: 30px !important;
    @media (min-width: 576px) {
    margin-block-end: 40px !important;
  }
    @media (min-width: 992px) {
    margin-block-end: 60px !important;
  }
}

.left-32-percent{
  inset-inline-start: 32%;
}
.top-10-percent{
  top: 10%;
}

.right-5-percent{
  inset-inline-end: 5%;
}
.top-35-percent {
  top: 35%;
}

.bottom-18-percent{
  bottom: 18%;
}
.left-3-percent{
  inset-inline-start: 3%;
}

.bottom-10-percent{
  bottom: 10%;
}
.left-17-percent{
  inset-inline-start: 17%;
}

.bottom-10-percent{
  bottom: 10%;
}
.left-7-percent{
  inset-inline-start: 7%;
}

.bottom-14-percent{
  bottom: 14%;
}
.left-65-percent{
  inset-inline-start: 65%;
}

.top-55-percent{
  top: 55%;
}
.left-30-percent{
  inset-inline-start: 30%;
}

.left-57-percent{
  inset-inline-start: 57%;
}

.top-18-percent{
  top: 18%;
}
.left-75-percent{
  inset-inline-start: 75%;
}

.top-10-percent{
  top: 10%;
}
.left-85-percent{
  inset-inline-start: 85%;
}

.left-83-percent{
  inset-inline-start: 83%;
}

.bottom-5-percent{
  bottom: 5%;
}
.left-70-percent{
  inset-inline-start: 70%;
}


.top-8-percent{
  top: 20%;
}
.right-8-percent{
  inset-inline-end: 8%;
}
.padding-top-100-px {
  padding-top: 100px;
}




.lg-ms-134px {
  @media (min-width: 991px) {
    margin-inline-start: 134px !important;
  }
}


/* ================================= Margin Css End =========================== */
